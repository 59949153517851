/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Paper } from '@mui/material';
import log from "loglevel";
import { Heading, suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Card from '../../common/Card';
import { useAppStateContext } from '../../../state/AppStateContext';
import { PageHeader, PageHeadingWrapper } from '../../common/CommonStyles';

const MiniSuomiAppList: React.FC = () => {
  const logger = log.getLogger(MiniSuomiAppList.name);
  const { t, i18n } = useTranslation();
  const appContext = useAppStateContext();

  // logger.debug("MiniSuomiAppInfos", appContext.miniSuomiAppInfos);
  // logger.debug("language", i18n.language);

  return (
    <>
      <PageHeader style={{marginTop: tokens.spacing.m, marginBottom: tokens.spacing.s}}>
        <PageHeadingWrapper>
          <Heading variant="h1" smallScreen>
            {t("app.components.MiniSuomiAppList.title")}
          </Heading>
        </PageHeadingWrapper>
      </PageHeader>
      <Grid container spacing={tokens.spacing.xs}>
        {appContext.miniSuomiAppInfos[i18n.language]?.map((info, index) => 
          <Grid item key={`appinfo-${index}`} xs={12} sm={6} md={4} lg={3}>
            <Box component={Paper} display="inline-flex" flexDirection="column" height= "100%" width= "100%">
              <Box display="flex" padding={tokens.spacing.s}>
                <PageHeadingWrapper>
                  {!info.headingImage &&
                    <Box display="flex" alignItems="center">
                      { info.appIcon &&
                        <Box paddingRight={tokens.spacing.xxs}>
                          <img src={info.appIcon} alt='Logo' style={{maxHeight: "4rem", maxWidth: "100%"}}/>
                        </Box>
                      }
                      <Box>
                        <Heading variant="h2" smallScreen color="brandBase" style={{padding: `${tokens.spacing.xs} 0`}}>
                          {info.heading}
                        </Heading>
                      </Box>
                    </Box>
                  }
                  {info.headingImage && <img src={info.headingImage} alt='Logo' style={{maxHeight: "4rem", maxWidth: "100%"}}/>}
                </PageHeadingWrapper>
              </Box>
              <Box display="flex" padding={tokens.spacing.s} flexGrow={1}>
                {info.description}
              </Box>
              <Box display="flex" padding={tokens.spacing.s} justifyContent="flex-end">
                {/* <Button color="primary">
                  {t("app.components.MiniSuomiAppList.moreInfoLink")}
                </Button> */}
                { info.appLink &&
                  <Button color="primary" href={info.appLink} target="_blank" sx={{textAlign: "right"}}>
                    {t("app.components.MiniSuomiAppList.linkToTheApp")}
                  </Button>
                }
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default MiniSuomiAppList;

{/* <PageHeader style={{
  padding: tokens.spacing.s, 
  backgroundImage: 'url("resources/images/yd-header.png"),linear-gradient(90deg,#18073a,#164194)', 
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat"
}}></PageHeader> */}